/* global */

class Home
{
    constructor () 
    {
        console.log('Home initialised');
    }
}

new Home;